
import { defineComponent } from 'vue'
import Article from '@/components/shared/Article.vue'
import HeadingComponent from '@/components/shared/HeadingComponent.vue'
import InfoBox from '@/components/shared/InfoBox.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  components: {
      Article,
      HeadingComponent,
      InfoBox,
      Footer
  },
})
